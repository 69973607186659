<template>
    <div class="sub-table">
        <InstallmentsTable 
            @selected-bills="selectedBills"
            :installments="installments" 
            :onEdit="installment => onEdit(bill?.id, bill?.bill_items?.length)"
            :getInstallments="getBills"
        />
    </div>
</template>
<script>
export default {
    props: {
        bill: Object,
        type: String,
        onEdit: Function,
        getBills: Function
    },
    components: {
        InstallmentsTable: () => import('@/modules/financial/components/InstallmentsTable'),
    },
    mounted() {
        this.loadInstallments()
    },
    data() {
        return {
            installments: [],
        }
    },
    methods: {
        selectedBills(billsToPay, billsToReceive) {
            this.$emit('selected-bills', billsToPay, billsToReceive)
        },
        loadInstallments() {
            if (!this.bill) {
                this.installments = []
                return
            }
            this.installments = this.bill.payments.reduce((acc, payment) => {
                const _installments = payment.installments.map(i => ({ ...i, payment: { ...payment, bill: this.bill } }))
                acc = acc.concat(_installments)
                return acc
            }, [])
        },
    },
    watch: {
        bill() {
            this.loadInstallments()
        }
    }
}
</script>
<style lang="scss" scoped>
    .sub-table {
        border: 1px solid var(--neutral-200);
        padding: 1rem;
        border-radius: 15px;
        margin: 1rem 0;
    }
</style>